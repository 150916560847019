@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

@font-face{
  font-family:"NanumSquareRoundOTF" ;
  font-weight: 300;
  src:url("./common/fonts/NanumSquareRoundOTFL.woff") format("woff");
}
@font-face{
  font-family:"NanumSquareRoundOTF" ;
  font-weight: 500;
  src:url("./common/fonts/NanumSquareRoundOTFR.woff") format("woff");
}
@font-face{
  font-family:"NanumSquareRoundOTF" ;
  font-weight: 600;
  src:url("./common/fonts/NanumSquareRoundOTFB.woff") format("woff");
}
@font-face{
  font-family:"NanumSquareRoundOTF" ;
  font-weight: 800;
  src:url("./common/fonts/NanumSquareRoundOTFEB.woff") format("woff");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Montserrat', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
